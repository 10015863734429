import { render, staticRenderFns } from "./listCommands.vue?vue&type=template&id=b802c466&scoped=true&"
import script from "./listCommands.vue?vue&type=script&lang=js&"
export * from "./listCommands.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b802c466",
  null
  
)

export default component.exports