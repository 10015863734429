<template>
  <div class="h100">

    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 @on-load="getList"
                 :page.sync="page"
                 :search.sync="search"
                 v-model="form">
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import {list} from '@/api/condition2.0/listCommands.js'
export default window.$crudCommon({
  components: {
  },
  methods: {
    getList(){
      list({page:this.page.currentPage,size:this.page.pageSize}).then(res=>{
        console.log(res)
        this.data = res;
        this.page.total = res.length;
      })
    }
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/condition2.0/listCommands',
  res: (data) => {
    console.log(data)
    return {
      total: data.data.length,
      data: data.data
    }
  }
})
</script>

<style lang="scss" scoped>
</style>